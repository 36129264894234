/* eslint-disable no-underscore-dangle */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useMemo } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { UserContext } from 'contexts/userContext';
import { withTranslation } from 'language/i18n';
import { SLIDER_TYPE } from 'constants/common';
import BannerItem from './BannerItem';

const Banner = ({ t, setVideoUrl, sliders = [] }) => {
  const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
    speed: 1000,
  };

  const { userData } = useContext(UserContext);
  const { isBuyer, isSeller } = userData;

  const sliderItems = useMemo(
    () => (isSeller === isBuyer
      ? sliders
      : _.filter(sliders, [
        'type',
        isBuyer ? SLIDER_TYPE.BUYER : SLIDER_TYPE.SELLER,
      ])),
    [isSeller, isBuyer, sliders],
  );

  return (
    <div className="banner">
      <div className="im-slider">
        <Slider {...sliderSettings}>
          {_.map(sliderItems, (slider) => (
            <BannerItem
              key={slider.id}
              content={slider}
              setVideoUrl={setVideoUrl}
            />
          ))}
        </Slider>
      </div>
      <div className="banner__bottom">
        {isBuyer && (
          <div className="im-notification im-notification--active im-notification--info">
            <div className="im-notification__inner">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-15 col-sm mb-3 mb-sm-0">
                    <p className="fs-18 text-center text-sm-left">
                      {t('homeActionStartedText')}
                      {' '}
                      <span className="fw-900">{t('homeStartBindingNow')}</span>
                    </p>
                  </div>
                  <div className="col-15 col-sm-auto text-center text-sm-left">
                    <Link href="/machinery">
                      <a className="im-button im-button--gutter-10 im-button--auto-height im-button--transparent-yellow2">
                        <span className="fs-18">{t('joinAuctionsToday')}</span>
                        <div className="im-button__icon im-button__icon--right-wider-gap">
                          <i className="fas fa-chevron-right" />
                        </div>
                      </a>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="clients banner__clients">
          <div className="container">
            <div className="row">
              <div className="col-15 col-sm-5 col-md-3">
                <div href="client-url" className="client">
                  <Image
                    src="/image/hurriyet-logo.png"
                    width="110"
                    height="30"
                  />
                </div>
              </div>
              <div className="col-15 col-sm-5 col-md-3">
                <div href="client-url" className="client">
                  <Image src="/image/cnn-logo.png" width="38" height="30" />
                </div>
              </div>
              <div className="col-15 col-sm-5 col-md-3">
                <div href="client-url" className="client">
                  <Image src="/image/forbes-logo.png" width="115" height="30" />
                </div>
              </div>
              <div className="col-15 col-sm-5 col-md-3">
                <div href="client-url" className="client">
                  <Image
                    src="/image/milliyet-logo.png"
                    width="120"
                    height="30"
                  />
                </div>
              </div>
              <div className="col-15 col-sm-5 col-md-3">
                <div href="client-url" className="client">
                  <Image
                    src="/image/webrazzi-logo.png"
                    width="120"
                    height="30"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Banner.propTypes = {
  t: PropTypes.func,
  setVideoUrl: PropTypes.func,
  sliders: PropTypes.array,
};

export default withTranslation('common')(Banner);
