import isUndefined from 'lodash/isUndefined';
import result from 'lodash/result';

export const getObjectByLanguage = (data) => {
  // const currentLanguage = i18n.language;
  const currentLanguage = 'tr';
  // eslint-disable-next-line no-unused-vars
  const value = result(data, `${currentLanguage}`);

  // if (!isUndefined(value)) {
  if (!isUndefined(data.tr)) {
    return data.tr;
  }
  if (data) {
    return data;
  }
  return '';
};
