/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { CallTo, MailTo } from 'components/common';

const useHomeFaqContent = () => {
  const homeFaqBuyer = [
    {
      image: {
        normal: '/image/icons.svg#ic-auction2',
        hover: '/image/icons.svg#ic-auction-yellow',
      },
      title: 'Açık Artırma',
      content: [
        {
          title: 'Açık artırmalar nerede gerçekleşiyor?',
          text: (
            <p>Açık artırmalar online ortamda gerçekleşmektedir. Bilgisayar, cep telefonu veya tablet aracılığıyla, online açık artırmalarımıza 7/24 katılım sağlayabilirsiniz.</p>
          ),
        },
        {
          title: 'Açık artırmalar ne zaman başlıyor? Ne kadar sürüyor?',
          text: (
            <p>iyiMakina.com açık artırmaları, sürekli olarak düzenlenmekte ve 30 gün sürmektedir. Satın almak istediğiniz araçların açıklama kısmında, açık artırma başlangıç tarihi ile ilgili bilgi mevcuttur.</p>
          ),
        },
        {
          title: 'Açık artırmaya katılmak ücretli mi? Teminat göstermek gerekiyor mu?',
          text: (
            <p>
              Açık artırmalara katılmak tamamen ücretsizdir lakin sabit Teminat Bedeli ödenmeden teklif verilememektedir. Alınan Teminat Bedeli, iyiMakina.com’un hesabında beklemekte ve kullanıcının talebi doğrultusunda koşulsuz iadesi sağlanabilmektedir. Bunun dışında herhangi bir ücret talep edilmemektedir. Teminat Bedeli ile ilgili her türlü soru ve detaylı bilgi almak için
              {' '}
              <CallTo tel="+90 850 885 46 25" />
              {' '}
              irtibat numarasından ya da
              {' '}
              <MailTo mail="info@iyimakina.com" />
              {' '}
              adresine mail atarak bize ulaşabilirsiniz.
            </p>
          ),
        },
        {
          title: 'Teminat Bedeli\'ni iade ediyor musunuz?',
          text: (
            <p>Teminat Bedeli'ni istediğiniz zaman, kesintisiz iade alabilirsiniz. İnternet sitemiz üzerinden Teminat Bedeli İade Talebi oluşturduğunuz takdirde, iadeniz bankanıza göre değişmekle beraber 1 ila 3 iş günü içerisinde gerçekleşecektir.</p>
          ),
        },
        {
          title: 'Teminat Bedeli\'ni neden talep ediyorsunuz?',
          text: (
            <p>iyiMakina.com, açık artırmaların güvenliğini sağlamak ve makinaların satış fiyatında yapılabilecek manipülasyonları engelleme amacıyla, açık artırmaya katılmak isteyen bütün kullanıcılardan Teminat Bedeli talep eder. Tüm kullanıcılardan alınan Teminat Bedeli aynıdır. Teminat Bedeli'nin alınmasının amacı kullanıcılara adil bir satış ortamı sunmaktır.</p>
          ),
        },
      ],
    },
    {
      image: {
        normal: '/image/icons.svg#ic-checklist2',
        hover: '/image/icons.svg#ic-checklist2-yellow',
      },
      title: 'Ekspertiz',
      content: [
        {
          title: 'Ekspertiz raporları neleri kapsıyor?',
          text: (
            <p>Türkiye temsilcisi tarafından yapılan bağımsız ekspertiz incelemesi, ikinci ise iyiMakina'nın uzman ekibi tarafından kayda alınan genel görünüm, hidrolik, şase kategorilerinde ortalama 100'ün üzerinde fotoğraf ve videoyu kapsayan çalışmadır.</p>
          ),
        },
        {
          title: 'Ekspertiz raporlarına güvenebilir miyim?',
          text: (
            <p>iyiMakina.com’un uzman ekibi aklınıza takılabilecek tüm sorulara cevap verebilmeniz için görsel olarak eksiksiz bir rapor oluşturmayı ve genellikle satışı planlanan makinanın Türkiye temsilci firma tarafından yapılan tetkiklerle beraber, oldukça güvenilir bir rapor ortaya çıkarmayı hedefler.</p>
          ),
        },
        {
          title: 'Satın almak istediğim makinanın ekspertiz raporunu nasıl inceleyebilirim?',
          text: (
            <p>Platformumuzda satışa çıkan makinaların detaylı ekspertiz raporları, makinanın ilan sayfasında yer almaktadır. Merak eden herkes için, detaylı görsel ve videoları ücretsiz olarak erişim sağlamak mümkündür.</p>
          ),
        },
        {
          title: 'Satıştaki makinayı yerinde/konumunda görebilir miyim? Sorularımı kime sorabilirim?',
          text: (
            <p>
              Teminat bedelini yatırmış her kullanıcı, ilgilendiği makinayı yerinde/konumunda görmek için randevu talep edebilir. Teminat ve randevu ile ilgili her türlü soru ve detaylı bilgi almak için
              {' '}
              <CallTo tel="+90 850 885 46 25" />
              {' '}
              irtibat numarasından ya da
              {' '}
              <MailTo mail="info@iyimakina.com" />
              {' '}
              adresine mail atarak bize ulaşabilirsiniz.,
            </p>
          ),
        },
      ],
    },
    {
      image: {
        normal: '/image/icons.svg#ic-transport2',
        hover: '/image/icons.svg#ic-transport-yellow',
      },
      title: 'Ödeme ve Teslimat',
      content: [
        {
          title: 'Açık artırmayı kazandım. Ödemeyi ne zamana kadar yapmalıyım?',
          text: (
            <p>Kazandığınız açık artırmanın bütün ödemesini, açık artırmanın sona ermesini takip eden 5 iş günü içerisinde tamamlamanız gerekmektedir. 5 iş günü içerisinde ödemenin tamamı yapılmayan makinalarda gecikme ücreti tahsil edilecektir.</p>
          ),
        },
        {
          title: 'Açık artırmayı kazandım. Makinayı nasıl teslim alacağım?',
          text: (
            <p>Açık artırmayı kazandığınız takdirde müşteri temsilciniz sizinle iletişime geçecektir. Nakliye, ödeme ve gerekli evraklar ile ilgili bilgiler tarafınıza iletilecektir. iyiMakina.com aracılığıyla satılan makinalar, olduğu gibi olduğu yerde satılmaktadır. iyiMakina.com aracılığıyla satılan makinaların nakliye sorumluluğu alıcıya aittir. Satıcı ve iyiMakina.com’un nakliye ile ilgili bir sorumluluğu yoktur.</p>
          ),
        },
        {
          title: 'Ödemeyi nereden ve nasıl yapabilirim? Hangi evraklara ihtiyacım olacak?',
          text: (
            <p>
              Açık artırmasını kazandığınız makinanın ödemesini banka hesaplarımıza havale/eft olarak yapabilirsiniz. Alım işlemleri için; Vergi Levhası, İmza Sirküleri, Alım Vekaletnamesi ve Sözleşmenin tarafımıza iletilmesi gerekmektedir. Makinaların evrak ihtiyacı ve ödeme ile ilgili her türlü soru ve detaylı bilgi almak için
              {' '}
              <CallTo tel="+90 850 885 46 25" />
              {' '}
              irtibat numarasından ya da
              {' '}
              <MailTo mail="info@iyimakina.com" />
              {' '}
              adresine mail atarak bize ulaşabilirsiniz.
            </p>
          ),
        },
        {
          title: 'Satın aldığım makinanın devrini nasıl alacağım, ne kadar sürüyor?',
          text: (
            <p>
              Satın alınan makinaların devri iyiMakina Bilgi Teknolojileri ve Ticaret A.Ş. üzerinden yapılacaktır. Makinanın ödemesinin tamamı yapılıp, gerekli evraklar iyiMakina.com'a ulaştırıldığı takdirde, ertesi iş günü içerisinde satış işlemleri tamamlanabilmektedir. Devir süreciyle ilgili her türlü soru ve detaylı bilgi almak için
              {' '}
              <CallTo tel="+90 850 885 46 25" />
              {' '}
              irtibat numarasından ya da
              {' '}
              <MailTo mail="info@iyimakina.com" />
              {' '}
              adresine mail atarak bize ulaşabilirsiniz.
            </p>
          ),
        },
      ],
    },
  ];

  const homeFaqSeller = [
    {
      image: {
        normal: '/image/icons.svg#ic-auction2',
        hover: '/image/icons.svg#ic-auction-yellow',
      },
      title: 'Açık Artırma',
      content: [
        {
          title: 'Açık artırmalar ne zaman başlıyor? Ne kadar sürüyor?',
          text: (
            <p>Satış talebinde bulunulan makinalar, ekibimiz tarafından detaylı ekspertizi yapıldıktan sonra, online açık artırma ile satışa çıkmaktadır. iyiMakina.com açık artırmaları, sürekli olarak düzenlenmekte ve 1-30 gün sürmektedir.</p>
          ),
        },
        {
          title: 'Açık artırmalar nerede gerçekleşiyor?',
          text: (
            <p>Açık artırmalar online ortamda gerçekleşmektedir. Bilgisayar, cep telefonu veya tablet aracılığıyla, online açık artırmalarımıza 7/24 katılım gerçekleşmektedir.</p>
          ),
        },
        {
          title: 'Makinamın açık artırma başlangıç fiyatı ve satış fiyatı nasıl belirleniyor? Kendim belirleyebiliyor muyum?',
          text: (
            <p>iyiMakina.com üzerinden açık artırmalara çıkarılan makinalar, “Rezerv” fiyatıyla birlikte, makina sahibinin onay verdiği fiyatlandırmayla satışa çıkacaktır. Makinanıza gelen teklifler, satmak istediğiniz tutarın altında kaldığı takdirde, satış iptal olacaktır.</p>
          ),
        },
        {
          title: 'Açık artırmada makinama gelen teklifler ne kadar güvenilir?',
          text: (
            <p>Açık artırmada gelen teklifler alıcının sorumluluğundadır. Tekliflerin geri dönüşü yoktur. Güvenilirlik ve manipülasyonu engellemek amacıyla, alıcılar teklif yapmadan önce Teminat Bedeli ödemektedir. Yapılan her teklif geçerli ve bağlayıcıdır.</p>
          ),
        },
        {
          title: 'Makinam açık artırmada satıldı. Makinayı nasıl teslim edeceğim?',
          text: (
            <p>Açık artırma, satıcının istediği fiyatın üzerinde satıldığı takdirde, müşteri temsilciniz sizinle iletişime geçecektir. Makinayı alıcının teslim alması için gerekli bilgiler makina sahibiyle paylaşılacaktır. Teslimat ve nakliye sorumluluğu alıcıya aittir.</p>
          ),
        },
        {
          title: 'Açık Artırma ile nasıl makina satabilirim?',
          text: (
            <p>İkinci el iş makinanızı internet üzerinden satmanızı sağlayan iyiMakina.com, makinanızı piyasa değerinde ve hızlı şekilde satmanıza yardımcı olur. Makinanız bulunduğu lokasyonda profesyonel ekspertiz ekibimiz tarafından incelenir ve online açık artırmaya çıkartılır. Türkiye ve Dünya genelinden birçok katılımcı makinanıza teklif vererek, adil rekabet ortamı oluşturulur. İş makinanızı değerinde, güvenilir ve hızlı şekilde satmanız sağlanır.</p>
          ),
        },
      ],
    },
    {
      image: {
        normal: '/image/icons.svg#ic-checklist2',
        hover: '/image/icons.svg#ic-checklist2-yellow',
      },
      title: 'Ekspertiz',
      content: [
        {
          title: 'Ekspertiz nerede yapılıyor? Ekspertiz için randevu almam gerekiyor mu?',
          text: (
            <p>
              Ekspertiz yapılacak makinanın kategorisini ve lokasyonunu belirterek, ekspertiz randevusu almanız gerekmektedir. Uzman ekspertiz ekibimiz, makinanızı bulunduğu lokasyonda kapsamlı şekilde inceleyip, rapor çıkartacaktır. Makinanızı bulunduğu yerde detaylı ekspertiz yaptırmak için detaylı bilgi ve randevu almak için
              {' '}
              <CallTo tel="+90 850 885 46 25" />
              {' '}
              irtibat numarasından bize ulaşabilirsiniz.
            </p>
          ),
        },
        {
          title: 'Ekspertiz için ücret ödeyecek miyim?',
          text: (
            <p>
              iyiMakina.com’un uzman ekspertiz ekibi, makinanızı bulunduğu yerde ziyaret ederek, kapsamlı ve eksiksiz ekspertiz raporu hazırlamaktadır. Makinanızın kategorisine ve modeline göre ekspertiz için ücret tahsil edilmektedir. Ekspertiz süreciyle ilgili her türlü soru ve detaylı bilgi almak için
              {' '}
              <CallTo tel="+90 850 885 46 25" />
              {' '}
              irtibat numarasından ya da
              {' '}
              <MailTo mail="info@iyimakina.com" />
              {' '}
              adresine mail atarak bize ulaşabilirsiniz.
            </p>
          ),
        },
        {
          title: 'Ekspertiz raporunuz neleri kapsıyor? Ekspertiz yaptırmak zorunda mıyım?',
          text: (
            <p>Ekspertiz raporlarımız; genel görünüm, kabin, motor, hidrolik, şase ve yağ analizi olmak üzere detaylı inceleme raporları, görüntüleri ve videolarını kapsamaktadır. Platformumuzdaki güvenilirlik ve manipulasyonu engellemek amacıyla satışa çıkan makinaların ekspertiz raporu olması gerekmektedir.</p>
          ),
        },
        {
          title: 'Kendi makinamı ekspertiz yapabilir miyim?',
          text: (
            <p>iyiMakina.com aracılığıyla satışa çıkan makinaların ekspertizi, uzman ekspertiz ekibimiz tarafından yapılmaktadır. Makina sahibinin veya 3. kişilerin yaptırdığı ekspertiz raporları platformumuzda geçerli değildir. Makinanıza ekspertiz randevusu almak için Formumuzu doldurabilirsiniz.</p>
          ),
        },
        {
          title: 'iyiMakina Güvencesi nedir?',
          text: (
            <p>iyiMakina.com’un uzman ekspertiz ekibinin makinaları bulunduğu lokasyonda, detaylı inceleyerek oluşturdukları ekspertiz raporları iyiMakina Güvencesi kapsamındadır. iyiMakina Güvencesi, sunduğu ekspertiz raporunun dışında; makinada eksik, hata ve hasar bulunmayacağını garanti etmektedir.</p>
          ),
        },
      ],
    },
    {
      image: {
        normal: '/image/icons.svg#ic-transport2',
        hover: '/image/icons.svg#ic-transport-yellow',
      },
      title: 'Satış ve Ödeme',
      content: [
        {
          title: 'Makinamı satmak ne kadar sürer?',
          text: (
            <p>iyiMakina.com aracılığıyla satışa çıkan makinalar; doğru alıcıyla, en hızlı ve en iyi fiyatla buluşmaktadır. Güven ve şeffaflık ortamında gerçekleşen, 30 gün süren açık artırmalar sonucunda, makinanız ülke genelinden alıcılarıyla buluşmaktadır.</p>
          ),
        },
        {
          title: 'Makinamın satışı tamamlandı. Ödemeyi nasıl alacağım, ne kadar sürer?',
          text: (
            <p>Makinanızın satış tutarının tamamı, alıcı tarafından 5 iş günü içerisinde ödenecektir. Gerekli mali işlemlerin tamamı iyiMakina.com tarafından gerçekleştirilip, satıcıya havale/eft olarak transfer edilecektir.</p>
          ),
        },
        {
          title: 'Satılan makinaların nakliyesi kimin sorumluluğunda?',
          text: (
            <p>Satılan makinaların nakliye sorumluluğu alıcıya aittir. Satıcı ve iyiMakina.com’un nakliye ile ilgili bir sorumluluğu yoktur.</p>
          ),
        },
        {
          title: 'Sattığım makinanın devrini nasıl vereceğim, ne kadar sürüyor?',
          text: (
            <p>
              Satılan makinaların devri iyiMakina Bilgi Teknolojileri A.Ş. üzerinden yapılacaktır. Makinanın ödemesinin tamamı yapılıp, gerekli evraklar iyiMakina.com'a ulaştırıldığı takdirde, ertesi güne kadar devir işlemleri tamamlanabilmektedir. Devir süreciyle ilgili her türlü soru ve detaylı bilgi almak için
              {' '}
              <CallTo tel="+90 850 885 46 25" />
              {' '}
              irtibat numarasından ya da
              {' '}
              <MailTo mail="info@iyimakina.com" />
              {' '}
              adresine mail atarak bize ulaşabilirsiniz.
            </p>
          ),
        },
        {
          title: 'Makinama en yüksek teklifi veren vazgeçti. Şimdi ne olacak?',
          text: (
            <p>Açık artırmayı kazanan alıcı satın almaktan vazgeçerse; teminat bedeli iadesi edilmez ve hakkını kaybeder. Bu durumda ikinci en yüksek teklifte bulunan alıcı açık artırmayı kazanmış olur. Makina sahibi ikinci en yüksek teklifi kabul ettiği takdirde satış onaylanmış olur.</p>
          ),
        },
      ],
    },
  ];

  return { homeFaqBuyer, homeFaqSeller };
};

export default useHomeFaqContent;
