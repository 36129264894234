/* eslint-disable no-underscore-dangle */
import React from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { withTranslation } from 'language/i18n';
import { MACHINE_STATE } from 'constants/common';
import AuctionItem from './AuctionItem';

const Auctions = ({ t, machines, classNameForBG }) => (
  <section className={classNameForBG}>
    <div className="container">
      <div className="row">
        <div className="col">
          <h2 className="section__header__title section__header__title--mb-45 text-center">{t('otherUpcomingAuctions')}</h2>
        </div>
      </div>
      <div className="row row--mb30">
        <div className="col-15">
          <div className="row justify-content-center">
            {
              _.map(machines, (machine) => (
                <AuctionItem machine={machine} key={machine.id} />
              ))
            }

          </div>
        </div>
      </div>
      <div className="col text-center">
        <Link href={`/machinery?state=${MACHINE_STATE.WAITING_EVENT_DATE}`}>
          <a className="im-button im-button--primary" href="/faq/buyers">{t('viewUpcomingAuctions')}</a>
        </Link>
      </div>
    </div>
  </section>
);

Auctions.propTypes = {
  machines: PropTypes.array,
  t: PropTypes.func,
  classNameForBG: PropTypes.string,
};

export default withTranslation('common')(Auctions);
