import React from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import Image from 'next/image';

import { withTranslation } from 'language/i18n';
import { SLIDER_TYPE } from 'constants/common';

const BannerItem = ({ t, content, setVideoUrl }) => {
  return (
    <div className="banner__inner" style={{ width: '100%' }}>
      <Image
        src={content.image}
        layout="fill"
        objectFit="cover"
        className="homepage-slider-image"
        priority
      />
      <div className="container banner__detail">
        <>
          <div className="row">
            <div className="col">
              <h1 className="banner__title">{content.title}</h1>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p className="banner__description">{content.subTitle}</p>
            </div>
          </div>
        </>

        <div className="row align-items-center">
          <div className="col-15 col-sm-auto">
            <Link
              href={
                content.type === SLIDER_TYPE.BUYER
                  ? '/machinery'
                  : '/machine-sales'
              }
            >
              <a className="im-button im-button--primary mr-2 im-button--mobile-fluid im-button--mobile-center">
                {t(
                  content.type === SLIDER_TYPE.BUYER
                    ? 'wantToBuyMachine'
                    : 'wantToSellMachine',
                )}
              </a>
            </Link>
          </div>
          <div className="col-15 col-sm-auto mt-3 mt-sm-0">
            <a
              className="im-button im-button--outline-white im-button--mobile-fluid im-button--mobile-center"
              onClick={() => setVideoUrl(
                content.type === SLIDER_TYPE.BUYER
                  ? 'https://www.youtube.com/embed/XrMdGdKiP6w?autoplay=1&amp;autohide=1&amp;fs=1&amp;rel=0&amp;hd=1&amp;wmode=transparent&amp;enablejsapi=1&amp;html5=1'
                  : 'https://www.youtube.com/embed/ZCeZBGGlVHg?autoplay=1&amp;autohide=1&amp;fs=1&amp;rel=0&amp;hd=1&amp;wmode=transparent&amp;enablejsapi=1&amp;html5=1',
              )}
            >
              <i className="fas fa-play-circle mr-3" />
              <span>{t('howDoesItWork')}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

BannerItem.propTypes = {
  content: PropTypes.object,
  setVideoUrl: PropTypes.func,
  t: PropTypes.func,
};

export default withTranslation('common')(BannerItem);
